import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("ai/innerStudy/usageHabit", { params });
}

export function Add(params) {
  return request.post("tcm/disease/", params);
}

export function Detail(params) {
  return request.get("tcm/disease/detail?id=" + params);
}

export function Update(params) {
  return request.put("tcm/disease/", params);
}

export function Delete(data) {
  return request.delete("tcm/disease/", { data });
}
