<template>
  <div>
    <div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id"
        :pagination="false" @change="search">
        <!-- <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span>
                <a @click="getInnerStudy(record)">详情</a>
              </span>
            </div>

          </div>
        </template> -->

        <template v-for="col in ['useDiseases', 'useSyndromes', 'usePulse']" #[col]="{ text, record }" :key="col">
          <div v-if="text"><check-outlined style="color:#389e0d;font-size:20px" /></div>
        </template>
        <template v-for="col in ['usageRatio']" #[col]="{ text, record }" :key="col">
          <a-progress type="circle" :percent="text" width="75px" :stroke-color="{
            '0%': '#108ee9',
            '100%': '#87d068',
          }">
            <template #format="percent">
              <span>{{ percent }}%</span>
            </template>
          </a-progress>
        </template>

      </a-table>
    </div>
    <button @click="">测试</button>
  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}

.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined,
  CheckOutlined
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search } from "/src/api/usageHabit.js";

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    CheckOutlined
  },
  name: "使用习惯统计",
  data() {
    return {
      visible: false,
      // pageSize: 10,
      inputs: {
        category: undefined,
      },

      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "病",
          key: "useDiseases",
          dataIndex: "useDiseases",
          width: "12.5%",
          slots: {
            customRender: "useDiseases"
          }
        },
        {
          title: "症",
          key: "useSyndromes",
          dataIndex: "useSyndromes",
          ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "useSyndromes"
          },
        },
        {
          title: "脉",
          width: "12.5%",
          key: "usePulse",
          dataIndex: "usePulse",
          slots: {
            customRender: "usePulse"
          }
        },
        {
          title: "次数",
          key: "prescriptionCount",
          dataIndex: "prescriptionCount",
          // ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "prescriptionCount"
          }
        },
        {
          title: "比例",
          key: "usageRatio",
          dataIndex: "usageRatio",
          ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "usageRatio"
          }
        },

        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: "12.5%",
        //   slots: {
        //     customRender: "operation"
        //   }
        // }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {

  },
  methods: {

    // getInnerStudy(record) {
    //   this.visible = true
    //   this.selectedRow = { ...record }
    // },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;

      let res = await Search()
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.data = res.data;
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
  }
};
</script>